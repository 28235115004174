var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-9"},[_c('h1',[_vm._v("Tạo combo khóa học 1-1")]),_c('ValidationObserver',{ref:"add_combo"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Tên combo")]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","name":'title',"placeholder":'Nhập tên combo'},model:{value:(_vm.combo.title),callback:function ($$v) {_vm.$set(_vm.combo, "title", $$v)},expression:"combo.title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Tên cấp độ")]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","name":'level',"placeholder":'Nhập tên cấp độ'},model:{value:(_vm.combo.level),callback:function ($$v) {_vm.$set(_vm.combo, "level", $$v)},expression:"combo.level"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Giá combo")]),_c('ValidationProvider',{attrs:{"name":"price","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{attrs:{"label":'',"placeholder":'Nhập giá combo',"readonly":false,"dense":true,"disabled":false,"outlined":true,"clearable":true,"valueWhenIsEmpty":'',"options":_vm.options},model:{value:(_vm.combo.price),callback:function ($$v) {_vm.$set(_vm.combo, "price", $$v)},expression:"combo.price"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0] ? 'Giá combo không được để trống' : '')+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Số lượng buổi học")]),_c('ValidationProvider',{attrs:{"name":"number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","name":'title',"type":"number","placeholder":'Nhập số lượng'},model:{value:(_vm.combo.number_lesson),callback:function ($$v) {_vm.$set(_vm.combo, "number_lesson", $$v)},expression:"combo.number_lesson"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{staticClass:"mx-2",attrs:{"label":"Giảm giá combo"},model:{value:(_vm.combo.sale),callback:function ($$v) {_vm.$set(_vm.combo, "sale", $$v)},expression:"combo.sale"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Giá sau khi giảm")]),_c('vuetify-money',{attrs:{"label":'',"placeholder":'Nhập giá combo',"readonly":false,"dense":true,"disabled":false,"outlined":true,"clearable":true,"valueWhenIsEmpty":'',"options":_vm.options},model:{value:(_vm.combo.amount),callback:function ($$v) {_vm.$set(_vm.combo, "amount", $$v)},expression:"combo.amount"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CkContent',{attrs:{"content":_vm.combo.description},on:{"getData":function (value) { return _vm.combo.description = value; }}})],1)],1)],1),_c('v-row',{staticClass:"d-flex align-center justify-center my-9"},[_c('v-col',{staticClass:"d-flex align-center justify-center text-center",attrs:{"cols":"5"}},[_c('HnrButton',{staticClass:"text-body-1",attrs:{"color":"primary","width":"200px","text":'Thêm combo'},on:{"hnrClickEvent":_vm.addProduct}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }